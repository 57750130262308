import React from 'react'
import { Box, Typography, Avatar, Paper, Grid } from '@mui/material'
import { ThemeContext } from '@emotion/react'

const testimonials = [
    {
        name: 'DaWane Wanek',
        targetrole: 'EVP Sales',
        avatar: '/images/headshots/wanek.jpg',
        testimonial: 'That was VERY impressive. Shows that you “get it” and that AI is a natural compliment to human feedback.'
    },
    {
        name: 'Sergei Shevlyagin',
        targetrole: 'Lead Developer',
        avatar: '/images/headshots/shevlyagin.jpg',
        testimonial: 'Impressed by level of detail and quality!'
    },
    {
        name: 'Rob Symington',
        targetrole: 'Director Product Management',
        avatar: '/images/headshots/symington.jpg',
        testimonial: 'I do not care that this was AI assisted at all. If I simply replace my bullets with the ones you provided there is no kind of AI signature that could potentially be found and maybe kick out my resume.'
    },
    // Add more testimonials as needed
]

function Testimonials() {
    return (
        <Box my={4}>
            <Typography variant="h4" gutterBottom textAlign="center">
                What Our Customers Say
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {testimonials.map((testi, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Avatar src={testi.avatar} alt={testi.name} sx={{ width: 56, height: 56, mb: 2 }} />
                                <Box width="100%" bgcolor="primary.main">
                                    <Typography fontWeight="bold" variant="subtitle1" textAlign="center" sx={{my: "5px"}}>
                                        {testi.name}
                                    </Typography>
                                </Box>
                                <Typography variant="subtitle2" display="block" gutterBottom>
                                    {testi.targetrole}
                                </Typography>
                                <Typography variant="body2" textAlign="center">
                                    "{testi.testimonial}"
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Testimonials