import React, { useState } from 'react'

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import { Typography, Box } from '@mui/material'


function SimpleBarChart({ resumejson, jobdescriptionjson, viewtype }) {
    //viewtype = "Leadership Principles" or "Competencies"
    const [loading, setLoading] = useState(true)

    const [activeItem, setActiveItem] = useState(null)
    const [chartData, setChartData] = useState([])
    const [resumeData, setResumeData] = useState([])
    const [jobdescriptionData, setJobDescriptionData] = useState([])
    
    useState(() => {
            const sortedResumeKeys = Object.keys(resumejson).sort((b,a) => resumejson[b].rank - resumejson[a].rank)

            const sortedJobDescriptionKeys = Object.keys(jobdescriptionjson).sort((b,a) => jobdescriptionjson[b].rank - jobdescriptionjson[a].rank)

            const allKeys = [...new Set([...sortedResumeKeys, ...sortedJobDescriptionKeys])]


            const chartData = allKeys.map((item, index) => {
                return {
                    name: item,
                    r_count: resumejson[item] ? resumejson[item].timesDemonstrated : 0,
                    jd_count: jobdescriptionjson[item]? jobdescriptionjson[item].timesDemonstrated : 0
                }
            })

            setChartData(chartData)
            //setCompetencynChartData(jobdescriptionData)

            setLoading(false)

        }, [resumejson, jobdescriptionjson])

    const handleMouseOver = (data, index) => {
        setActiveItem(data)
    }

    const handleMouseOut = () => {
        //setActiveItem(null)
    }

    if (loading) {
        return <Typography>Loading data...</Typography>
    }

    return (
        <>
            <ResponsiveContainer width="100%" height={700}>
                <BarChart
                    layout="horizontal"
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="number" />
                    <XAxis
                        dataKey="name"
                        type="category"
                        height={200}
                        angle={-45}
                        textAnchor="end"
                        width={100}
                    />
                    <Tooltip />
                    <Legend verticalAlign='top'/>
                    <Bar dataKey="r_count" name="Count from Resume" fill="#8884d8" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
                    <Bar dataKey="jd_count" name="Count from Job Description" fill="#82ca9d" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
                </BarChart>
            </ResponsiveContainer>
            <Box minHeight="400px">
                {activeItem && (
                    <>
                        <Typography fontWeight="bold" variant="h6">
                            {activeItem.name}
                        </Typography>
                        <Typography variant="body1" sx={{ mt: "10px" }}>
                            Resume: {resumejson[activeItem.name]?.timesDemonstrated || 0}{" "}
                            {resumejson[activeItem.name]?.timesDemonstrated  === 1 ? "item" : "items"}
                        </Typography>
                        {resumejson[activeItem.name]?.demonstratedText ? (
                            <ol>
                                {resumejson[activeItem.name].demonstratedText.map((text, index) => (
                                    <li key={index}>"{text}"</li>
                                ))}
                            </ol>
                        ) : `Text for this ${viewtype} was not demonstrated in your resume.`}
                            <Typography variant="body1" sx={{ mt: "10px" }}>
                                Job Description: {jobdescriptionjson[activeItem.name]?.timesDemonstrated || 0}{" "}
                                {jobdescriptionjson[activeItem.name]?.timesDemonstrated === 1 ? "item" : "items"}
                            </Typography>
                        {jobdescriptionjson[activeItem.name]?.demonstratedText ? (
                            <ol>
                                {jobdescriptionjson[activeItem.name].demonstratedText.map((text, index) => (
                                    <li key={index}>"{text}"</li>
                                ))}
                            </ol>
                        ) : `Text for this ${viewtype} was not found in the job description.`}
                    </>
                )}
            </Box>
        </>
    )
}

export default SimpleBarChart