import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Typography, Box, Select, MenuItem, IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';

import ContentCopyIcon from '@mui/icons-material/ContentCopy'

function ResumeView({ data }) {
    const [selectedCompanyInfo, setSelectedCompanyInfo] = useState("")
    const [companyInfo, setCompanyInfo] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const rolesWithCompanyName = Object.entries(data.companyInfo).flatMap(([companyname, companydata]) =>
            companydata.roles.map(role => ({
                ...role,
                companyname,
                "key": uuidv4()
            }))
        ).sort((a, b) => a.companyname.localeCompare(b.companyname))

        setCompanyInfo(rolesWithCompanyName)
        setSelectedCompanyInfo(rolesWithCompanyName[0])

        setLoading(false)

    }, [])

    function handleSelectChange(event) {
        setSelectedCompanyInfo(companyInfo.find(company => company.key === event.target.value))
    }

    const copyNewBulletsToClipboard = () => {
        const bulletsText = selectedCompanyInfo.orderedGptBullets.map(bulletData => bulletData.bullet).join('\n')
        navigator.clipboard.writeText(bulletsText)
    }

    if (loading) return <Typography>Loading...</Typography>

    return (
        <>
            <Box sx={{ width: '100%', px: "80px" }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ paddingRight: '10px', fontSize: '1.1rem' }} variant="h6" fontWeight={"bold"}>Resume Roles:</Typography>
                    <Select value={selectedCompanyInfo.key} onChange={handleSelectChange}>
                        {companyInfo.map((company, index) => (
                            <MenuItem key={company.key} value={company.key}>{company.companyname} - {company.role}</MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Typography variant='h6' fontWeight={"bold"} sx={{ fontSize: '1.1rem' }}>Old Bullets:</Typography>
                    {
                        (selectedCompanyInfo.bullets && selectedCompanyInfo.bullets.length) > 0 ?
                            <ul>
                                {selectedCompanyInfo.bullets.map((bullet, index) => (
                                    <li key={index}>{bullet}</li>
                                ))}
                            </ul> :
                            <Typography variant='body1'>No bullets found</Typography>
                    }
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h6' fontWeight={"bold"} sx={{ fontSize: '1.1rem' }}>New Bullets:</Typography>
                        <Tooltip
                            title="Copy New Bullets to Clipboard"
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        fontSize: '1.25rem', // Adjust the font size as needed
                                    }
                                }
                            }}
                        >
                            <IconButton
                                onClick={copyNewBulletsToClipboard}
                                size="small"
                                sx={{
                                    ml: 1,
                                    backgroundColor: 'primary.main', // Change the color as needed
                                    color: 'white', // Change the icon color as needed
                                    '&:hover': {
                                        backgroundColor: 'primary.dark', // Change the hover color as needed
                                    },
                                    borderRadius: '50%', // Makes it circular
                                }}
                            >
                                <ContentCopyIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {
                        selectedCompanyInfo.orderedGptBullets && selectedCompanyInfo.orderedGptBullets.length > 0 ?
                            <ul>
                                {selectedCompanyInfo.orderedGptBullets.map((bulletData, index) => (
                                    <li key={index}>{bulletData.bullet}<br />
                                    </li>
                                ))}
                            </ul>
                            :
                            <Typography variant='body1'>No new bullets generated</Typography>
                    }
                    {selectedCompanyInfo.gptSuggestedMetrics && (
                        <>
                            <Typography variant='h6' fontWeight={"bold"} sx={{ fontSize: '1.1rem' }}>Results/Metrics Suggestions for Bullets:</Typography>
                            <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>{selectedCompanyInfo.gptSuggestedMetrics}</Typography>
                        </>
                    )}
                    {selectedCompanyInfo.gptNewBulletAnalysis &&
                        <>
                            <Typography variant='h6' fontWeight={"bold"} sx={{ fontSize: '1.1rem', mt: '10px' }}>New Bullet Analysis:</Typography>
                            <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>{selectedCompanyInfo.gptNewBulletAnalysis}</Typography>
                        </>
                    }
                </Box>
            </Box>
        </>
    )
}

export default ResumeView