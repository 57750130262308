import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import { v4 } from 'uuid'

import { Auth, API, graphqlOperation } from 'aws-amplify'
import { customerByCustomerEmail } from '../graphql/queries'
//not sure i need this one yet
import { createCustomer } from '../graphql/mutations'

import ResumeLicensePurchase from '../components/ResumeLicensePurchase'


function ResumeLicenseCheck( {user} ) {
    //obviously we need to hide this in an env variable
    const navigate = useNavigate()
    const [hasLicense, setHasLicense] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function checkLicense() {
            setIsLoading(true)
            try {
                const user = await Auth.currentAuthenticatedUser()
                const groups = user.signInUserSession.accessToken.payload["cognito:groups"]

                if (groups && groups.includes('Admin')) {
                    //console.log("User is an admin, navigating to orders")
                    setIsAdmin(true)
                    navigate('/resumereports')
                }
                else {
                    // console.log("user.email:", user.attributes.email)
                    const response  = await API.graphql(graphqlOperation(customerByCustomerEmail, {
                        email: user.attributes.email
                    }))
                    const customer = response.data.customerByCustomerEmail.items
            
                    //console.log("customer:", customer)

                    if (customer.length === 0) {
                        //console.log(`customer with ${user.attributes.email} not found; need to create a new one`)
                        //create a new customer
                        const newCustomer = {
                            id: v4(),
                            email: user.attributes.email,
                            resumereportcredits: 0
                        }
                        await API.graphql(graphqlOperation(createCustomer, { input: newCustomer }))

                        //this page will need to remain in place to allow for the purcahse of a license
                    }
                    else if (customer.length === 1) {
                        // console.log("user:", user)
                        // console.log(`customer with ${user.attributes.email} found`)
                        if (customer[0].resumereportcredits > 0) {
                            setHasLicense(true)
                            navigate('/resumereports')
                        }
                        // else {
                        //     console.log("customer has not purchased a license, so we need to display this page")
                        // }
                    }
                }

            } catch (err) {
                console.log('error: ', err)
            }
            finally {
                setIsLoading(false)
            }
        }
        checkLicense()
    }, [user, navigate])

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isAdmin || hasLicense) {
        return null
    }

    return <ResumeLicensePurchase user={user} />
}


export default  ResumeLicenseCheck