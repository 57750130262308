/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      email
      orders {
        nextToken
        __typename
      }
      resumereportcredits
      interviewruncredits
      hiregmeetingruncredits
      stripecustomerid
      stripetransactions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      createdAt
      resume {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      resumeid
      jobdescription {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      jobdescriptionid
      analysis {
        id
        orderid
        completed
        resumejson
        jobdescriptionjson
        tokensjson
        stepfunctionid
        createdAt
        updatedAt
        owner
        __typename
      }
      analysisid
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      targetrole
      servicelevel
      processingcomplete
      updatedAt
      customerOrdersId
      owner
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResume = /* GraphQL */ `
  query GetResume($id: ID!) {
    getResume(id: $id) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listResumes = /* GraphQL */ `
  query ListResumes(
    $filter: ModelResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobDescription = /* GraphQL */ `
  query GetJobDescription($id: ID!) {
    getJobDescription(id: $id) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listJobDescriptions = /* GraphQL */ `
  query ListJobDescriptions(
    $filter: ModelJobDescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobDescriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnalysis = /* GraphQL */ `
  query GetAnalysis($id: ID!) {
    getAnalysis(id: $id) {
      id
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      completed
      resumejson
      jobdescriptionjson
      tokensjson
      stepfunctionid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAnalyses = /* GraphQL */ `
  query ListAnalyses(
    $filter: ModelAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderid
        completed
        resumejson
        jobdescriptionjson
        tokensjson
        stepfunctionid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStripeTransaction = /* GraphQL */ `
  query GetStripeTransaction($id: ID!) {
    getStripeTransaction(id: $id) {
      id
      customerid
      stripeCustomerid
      transactionid
      transactiondatetime
      status
      paymentmethodid
      productid
      credits
      amount
      currency
      description
      receipturl
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStripeTransactions = /* GraphQL */ `
  query ListStripeTransactions(
    $filter: ModelStripeTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerid
        stripeCustomerid
        transactionid
        transactiondatetime
        status
        paymentmethodid
        productid
        credits
        amount
        currency
        description
        receipturl
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerByCustomerEmail = /* GraphQL */ `
  query CustomerByCustomerEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByCustomerEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerByStripeCustomerId = /* GraphQL */ `
  query CustomerByStripeCustomerId(
    $stripecustomerid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByStripeCustomerId(
      stripecustomerid: $stripecustomerid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByCreatedDate = /* GraphQL */ `
  query OrdersByCreatedDate(
    $createdAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCreatedDate(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderByServiceLevel = /* GraphQL */ `
  query OrderByServiceLevel(
    $servicelevel: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByServiceLevel(
      servicelevel: $servicelevel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const resumeByOrderId = /* GraphQL */ `
  query ResumeByOrderId(
    $orderid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resumeByOrderId(
      orderid: $orderid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const jobDescriptionByOrderId = /* GraphQL */ `
  query JobDescriptionByOrderId(
    $orderid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobDescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobDescriptionByOrderId(
      orderid: $orderid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const analysisByOrderId = /* GraphQL */ `
  query AnalysisByOrderId(
    $orderid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    analysisByOrderId(
      orderid: $orderid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderid
        completed
        resumejson
        jobdescriptionjson
        tokensjson
        stepfunctionid
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByCustomer = /* GraphQL */ `
  query TransactionsByCustomer(
    $customerid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStripeTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByCustomer(
      customerid: $customerid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerid
        stripeCustomerid
        transactionid
        transactiondatetime
        status
        paymentmethodid
        productid
        credits
        amount
        currency
        description
        receipturl
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
