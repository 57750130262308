import React, { useEffect } from 'react'
import { Box, Typography, Button, Paper, Container } from '@mui/material'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'

import { useNavigate } from 'react-router-dom'

function ResumeCancelPurchase() {
    const navigate = useNavigate()
    const resumereports = '/'

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(resumereports)
        }, 7000)

        return () => clearTimeout(timer)
    }, [navigate])

    const handleRedirect = () => {
        navigate(resumereports)
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ mt: 8, p: 4, textAlign: 'center' }}>
                <SentimentDissatisfiedIcon sx={{ fontSize: 100, color: 'action.active' }} />
                <Typography variant="h4" gutterBottom>
                    Purchase Cancelled
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    It seems like the payment process was not completed. But don't worry,
                    you can attempt the purchase again at any time.
                </Typography>
                <Typography variant="body1" sx={{ my: 2 }}>
                    You will be redirected back to the homepage in a few moments.
                    If you wish to proceed immediately, click the button below.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRedirect}
                >
                    Return to Homepage
                </Button>
            </Paper>
        </Container>
    )
}

export default ResumeCancelPurchase