import React from 'react'
import { Typography, Link, Box } from '@mui/material'

function Footer() {
    const links = [
        {
            'title': 'Contact me',
            'href': 'mailto:bwatson@kompartners.com',
            'active': false
        },
        {
            'title': 'Privacy Policy',
            'href': '/privacy-policy',
            'active': false
        },
        {
            'title': 'Terms of Service',
            'href': '/terms-of-service',
            'active': false
        }
    ]

    return (
        <Box my={4}>
            {links.map((link, index) => (
                link.active ?
                    <Link key={index} href={link.href} variant="body1" color="textSecondary" sx={{ mr: 2 }}>
                        {link.title}
                    </Link>
                    :
                    null
            ))}
            <Typography variant="body2" color="textSecondary" align="center">
                {'Built with ❤️ by '}
                <Link color="inherit" href="https://www.twitter.com/brandonwatson">
                    Brandon Watson
                </Link>

            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                &copy; {new Date().getFullYear()} KOM Partners, LLC
            </Typography>
        </Box>
    )
}

export default Footer