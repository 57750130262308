import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

import { Typography, Box, ButtonGroup, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress  } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

import { API, graphqlOperation, Auth } from 'aws-amplify'
import { getOrderDetails } from '../graphql/customqueries'

import SummaryView from '../components/SummaryView'
import ResumeView from '../components/ResumeView'
import CompetencyView from '../components/CompetencyView'
import TokensView from '../components/TokensView'

import ReactGA from 'react-ga4'

function OrderDetails( { user }) {
    const navigate = useNavigate()
    const { id } = useParams()

    const [orderDetails, setOrderDetails] = useState(null)
    const [resumejson, setResumeJson] = useState(null)
    const [jobdescriptionjson, setJobDescriptionJson] = useState(null)
    const [tokensjson, setTokensJson] = useState(null)
    const [loading, setLoading] = useState(true)
    const [activeView, setActiveView] = useState('summary')
    const [isAdmin, setIsAdmin] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)

    const dataviews = {
        'summary': {
            'heading': "Summary",
            'button': "Summary",
            'requiresadmin': 'false'
        },
        'resumejson': {
            'heading': "Your Resume",
            'button': "Resume",
            'requiresadmin': 'false'
        },
        'leadershipprinciples': {
            'heading': "Leadership Principles",
            'button': "Leadership Principle Analysis",
            'requiresadmin': 'false'
        },
        'competencies': {
            'heading': "Competencies",
            'button': "Compentency Analysis",
            'requiresadmin': 'false'
        },
        'tokens': {
            'heading': "Analytics Data",
            'button': "Cost Analytics - ADMIN ONLY",
            'requiresadmin': 'true'
        }
    }

    function handleViewChange(view) {
            //`event` method is called with: event name, category, label, value, and non-interaction flag.
        ReactGA.event({
            action: 'click',
            category: 'button_click',
            label: `ResumeOrderDetails: Customer clicked OrderDetails view key: ${view}`,
            value: 0,
            nonInteraction: false
        })
        
        setActiveView(view)
    }
    
    async function handleDownlad(id) {
        setIsDownloading(true)
        setDownloadDialogOpen(true)

        const stage = process.env.REACT_APP_USER_BRANCH 

        //console.log(`stage is: ${stage}, id is: ${id}`)

        let response
        try {
            response = await API.get('writeResumeReport-bigtechivy', `/downloadreport/${id}/${stage}`, {})
            console.log('Response: ', response)
        } catch (err) {
            console.error(err)
        } finally {
            if (response.status === 'success'){
                // console.log('TMP LOGGING: This is to trigger redeploy - We have a file to download')
                const url = response.url
                window.open(url, '_blank')
            }
            else {
                console.log('No file to download because body.status !== success')
            }
            setIsDownloading(false)
            setDownloadDialogOpen(false)
        }
    }

    useEffect(() => {
        async function fetchOrderDetails () {
            try {
                const orderData = await API.graphql(graphqlOperation(getOrderDetails, { id }))
                const parsedResumeJson = JSON.parse(orderData.data.getOrder.analysis.resumejson)
                const parsedJobDescrpitionJson = JSON.parse(orderData.data.getOrder.analysis.jobdescriptionjson)
                const parsedTokensJson = JSON.parse(orderData.data.getOrder.analysis.tokensjson)
    
                setOrderDetails(orderData.data.getOrder)
                setResumeJson(parsedResumeJson)
                setJobDescriptionJson(parsedJobDescrpitionJson)
                setTokensJson(parsedTokensJson)
    
                setLoading(false)
                // console.log("orderData:", orderData.data.getOrder)
            } catch (err) {
                console.error("Error fetching order details:", err)
                setLoading(false)
            }
        }
    
        async function fetchGroups() {
            try {
                // Assuming the user prop has the necessary attributes
                const groups = user.signInUserSession.idToken.payload['cognito:groups']
                if (groups && groups.includes('Admin')) {
                    setIsAdmin(true)
                }
            } catch (error) {
                    console.error('Error fetching user groups', error)
            }
        }

        fetchOrderDetails()
        if (user) {
            fetchGroups()
        }
        else {
            navigate('/')
        }
    }, [id, user])



    if (loading) return <Typography>Loading...</Typography>

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h3" sx={{mt: "10px", mb: "20px"}}>Order Details</Typography>
                <Button
                    endIcon={<DownloadIcon />}
                    onClick={() => {handleDownlad(id)}}
                    variant="contained"
                >
                    Download Report
                </Button>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Typography sx={{ paddingRight: '10px'}} variant="body1" fontWeight={"bold"}>Customer Email:</Typography>
                    <Typography variant="body1">{orderDetails.customer.email}</Typography>
                </Box>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Typography sx={{ paddingRight: '10px'}} variant="body1" fontWeight={"bold"}>Target Role:</Typography>
                    {/* <Typography variant="body1">{orderDetails.targetrole }</Typography> */}
                    <Typography variant="body1">{orderDetails.targetrole ? orderDetails.targetrole : jobdescriptionjson.jobDescriptionCompanyInfo.companyRole}</Typography>
                </Box>
            </Box>

            <ButtonGroup variant="contained" color="primary" sx={{margin: '20px auto', display: 'block'}}>
                {Object.keys(dataviews).map(key => {
                    // This is hacky and I should ficure out how to do this using dataiews properties
                    if (key === 'tokens' && !isAdmin) {
                        return null
                    }
                    // Otherwise, render the button
                    return (
                        <Button 
                            onClick={() => handleViewChange(key)}
                            variant={activeView === key ? 'contained' : 'outlined'}
                            key={key}
                        >
                            {dataviews[key].button}
                        </Button>
                    )
                })}
            </ButtonGroup>

            {/* <Typography variant="h4">{dataviews[activeView].heading}</Typography> */}

            {activeView === 'summary' && <SummaryView data={resumejson} />}
            {activeView === 'resumejson' && <ResumeView data={resumejson} />}
            {activeView === 'leadershipprinciples' && <CompetencyView 
                resumejson={resumejson.orderedLeadershipPrinciples}
                jobdescriptionjson={jobdescriptionjson.orderedLeadershipPrinciples}
                viewtype="Leadership Principle"
                />}
            {activeView === 'competencies' && <CompetencyView 
                resumejson={resumejson.orderedCompetencies}
                jobdescriptionjson={jobdescriptionjson.orderedCompetencies}
                viewtype="competency"
                />}
            {activeView === 'tokens'
                && isAdmin
                && <TokensView data={tokensjson} />}
            <Dialog
                open={downloadDialogOpen}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Resume Report Being Generated"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The file will download automatically. You may need to enable pop-ups for this site and re-download.
                    </DialogContentText>
                    <CircularProgress sx={{ m: 'auto', display: 'flex' }}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default OrderDetails