import React  from 'react'
import {  Container, Grid } from '@mui/material'

import ResumeReportCta from './ResumeReportCta'

function ResumeLicensePurchase( { user } ) {
    // const [productDetails, setProductDetails] = useState(null)
    
    // const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
    // const privateKey = process.env.REACT_APP_STRIPE_PRIVATE_KEY

    // const privatestripe = new Stripe(privateKey)

    const productIdList = process.env.REACT_APP_RESUME_LICENSE_IDS.split(",")

    // async function fetchProductDetails(productId) {
    //     try {
    //         const product = await privatestripe.products.retrieve(productId)
    //         // console.log("product: ", product)
    //         const prices = await privatestripe.prices.list({ product: productId })
    //         // console.log("prices: ", prices)
    //         setProductDetails({ product, prices }) // Assuming there's at least one price
    //     } catch (error) {
    //         console.error('Error fetching product details from PrivateStripe:', error)
    //     }
    // }

    // async function redirectToCheckout(priceid) {
    //     try {
    //         const user = await Auth.currentAuthenticatedUser()
    //         const token = user.signInUserSession.idToken.jwtToken
    //         const userid = user.username
    //         const useremail = user.attributes.email
    
    //         console.log("user: ", user)

    //         const credits = parseInt(productDetails.product.metadata.credits,10)
            
    //         const requestInfo = {
    //             headers: {
    //                 Authorization: token,
    //             },
    //             body: { priceid, userid, useremail, credits }
    //         }
    //         // console.log("requestInfo: ", requestInfo)
            
    //         //console.log("Stripe endpoint: ", awsmobile.aws_cloud_logic_custom[0].endpoint)

    //         const response = await API.post('checkoutStripe', '/checkout', requestInfo)

    //         //console.log("response: ", response)
    
    //         const { sessionId } = response // Parse the response body to get the session ID
    //         //console.log("OMG a sessionId: ", sessionId)
    //         // Redirect to Stripe Checkout using the session ID

    //         const publicstripe = await loadStripe(publicKey)
    //         publicstripe.redirectToCheckout({ sessionId })
    //     } catch (error) {
    //         console.error('ERROR: redirectToCheckout():', error)
    //     }
    // }

    // useEffect(() => {
    //     const productId = process.env.REACT_APP_RESUME_LICENSE_PRODID
    //     const productIdList = process.env.REACT_APP_RESUME_LICENSE_IDS

    //     fetchProductDetails(productId)
    // }, [])

    // if (!productDetails || !productDetails.prices || !productDetails.prices.data || productDetails.prices.data.length === 0) {
    //     return <Typography>Loading product details...</Typography>
    // }

    return (
        <Container maxWidth="lg">
            {/* <Box my={4} textAlign="center">
                <Typography variant="h4" gutterBottom>
                    Purchase  One-Time License
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                In order to use HiredUpon, you must purchase a usage credits. In this case you are buying credits to run Resume Reports.
                </Typography>
            </Box> */}
    
            <Grid container spacing={4} justifyContent="center">
                {/* <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ResumeLicenseProductCard
                        productDetails={productDetails}
                        purchaseClick={redirectToCheckout}
                        ctaText={ctaText}
                        />
                </Grid> */}
                {productIdList.map((productId) => {
                    return <ResumeReportCta key={productId} productId={productId} />
                })}
            </Grid>
    
            {/* <FeatureHighlights /> */}
            {/* <Testimonials /> */}
            {/* <LicenseFaq /> */}
        </Container>
    )
}

export default ResumeLicensePurchase