const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://5np5gsse4fdq3czz7tmxiwtuie.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-1:b0ab972a-1bae-4baf-9547-eaa5907edceb",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_En4yibUHx",
    "aws_user_pools_web_client_id": "o2i8utgfa90j7g06b805eeul3",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bitechivy-storage204859-prod",
    "aws_user_files_s3_bucket_region": "us-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "writeResumeReport-bigtechivy",
            "endpoint": "https://o1e0buulnl.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "checkoutStripe",
            "endpoint": "https://ai3gf8mg17.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        }
    ]
};
module.exports = awsmobile;
