import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'

import { API, Auth, graphqlOperation } from 'aws-amplify'
import { customerByCustomerEmail } from '../graphql/queries'

import ResumeOrderForm from '../components/ResumeOrderForm'

function NewResumeOrder({ user }) {
    const navigate = useNavigate()

    useEffect(() => {
        async function validateLicense (user) {
            const email = user.attributes.email
            const response  = await API.graphql(graphqlOperation(customerByCustomerEmail, {
                email: email
            }))
            const customer = response.data.customerByCustomerEmail.items
    
            if (customer.length === 0) {
                console.log("ERROR: something went wrong verifying customer in Orders.js")
                return false
            }
            else if (customer.length === 1) {
                if (customer[0].resumereportcredits === 0) {
                    return false
                }
            }
            return true
        }

        async function checkLicense() {
            const user = await Auth.currentAuthenticatedUser()
            const groups = user.signInUserSession.accessToken.payload["cognito:groups"]

            if (!user) {
                navigate('/')
            }  
            else {
                if (groups && groups.includes("Admin")) {
                    console.log("user is an admin, skipping out to New Order")
                }
                else {
                    const validlicense = await validateLicense(user)
                    console.log("Existing validlicense() value is:", validlicense)
                    if (!validlicense) {
                        console.log("ERROR: user does not have a valid license in Orders.js")
                        navigate('/resumelicensecheck')
                    }
                }
            }
        } 
        checkLicense()
    }, [user])

    if (!user) {
        //This ensures that nothing is rendered until the navigation side effect occurs.
        return null
    }

    return (
        <div>
            <Typography variant="h3" sx={{ mt: "10px", mb: "20px" }}>Create New Order</Typography>
            <ResumeOrderForm />
        </div>
    )
}

export default NewResumeOrder