import React, { useEffect, useState } from 'react'
import { Routes, Route, withRouter } from 'react-router-dom'

import { Hub, Auth } from 'aws-amplify'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'

import { Typography } from '@mui/material'

import Navbar from './components/Navbar'
import Footer from './components/Footer'

import LandingPage from './pages/LandingPage'
import NewResumeOrder from './pages/NewResumeOrder'
import ResumeReports from './pages/ResumeReports'
import ResumeOrderDetails from './pages/ResumeOrderDetails'
import Signin from './pages/Signin'
import ResumeLicenseCheck from './pages/ResumeLicenseCheck'
import ResumeSuccessPurchase from './pages/ResumeSuccessPurchase'
import ResumeCancelPurchase from './pages/ResumeCancelPurchase'

import usePageTracking from './customhooks/usePageTracking'

Amplify.configure(awsExports)

function App() {
    usePageTracking()
    const [user, setUser] = useState(null)

    useEffect(() => {
        checkUser()
        Hub.listen('auth', (data) => {
            const { payload } = data
            switch (payload.event) {
                case 'signIn':
                    setUser(payload.data)
                    break
                case 'signOut':
                    setUser(null)
                    break
                default:
                    break
            }
        })

        return () => Hub.remove('auth')
    }, [])

    async function checkUser() {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            setUser(currentUser);
        } catch (err) {
            //console.log('Not signed in');
            setUser(null);
        }
    }   
    

    return (
        <div>
            <Navbar user={user} />
            { process.env.REACT_APP_USER_BRANCH === 'dev' ?
                <>
                    <Typography variant="h3" sx={{backgroundColor: "red"}}>This is: git-DEVBRANCH; Amplify-{process.env.REACT_APP_USER_BRANCH}</Typography>
                    <Typography variant="h5" sx={{backgroundColor: "red"}}>This is not: git-PROD; Amplify-MAIN</Typography>
                </>
                :
                null
            }

            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/newresumeorder" element={<NewResumeOrder user={user}/>} />
                <Route path="/resumereports" element={<ResumeReports user={user}/>} />
                <Route path="/orderdetail/:id" element={<ResumeOrderDetails user={user}/>} />
                <Route path="/signin" element={<Signin user={user}/>} />
                <Route path="/resumelicensecheck" element={<ResumeLicenseCheck user={user}/>} />
                <Route path="/resumesuccesspurchase" element={<ResumeSuccessPurchase user={user}/>} />
                <Route path="/resumecancelpurchase" element={<ResumeCancelPurchase user={user}/>} />
            </Routes>
            <Footer />
        </div>
    )
}

export default App