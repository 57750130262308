import React, { useState, useEffect } from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'

function ResumeProcessingProgress({ resumejson }) {
    const targetkeys = {
        "companyInfo":
        {
            "index" : 1,
            "value": "Resume Company Info"
        },
        "eduInfo": 
        {
            "index" : 2,
            "value": "Resume Education Info"
        },
        "personalInfo":
        {
            "index" : 3,
            "value": "Resume Personal Info"
        },
        "summaryInfo":
        {
            "index" : 4,
            "value": "Summary Info"
        },
        "resumeCompetencies":
        {
            "index" : 5,
            "value": "Resume Competencies"
        },
        "resumeLeadershipPrinciples":
        {
            "index" : 6,
            "value": "Resume Leadership Principles"
        },
        "orderedCompetencies":
        {
            "index" : 7,
            "value": "Ordering the Competencies"
        },
        "orderedLeadershipPrinciples":
        {
            "index" : 8,
            "value": "Ordering the Leadership Principles"
        },
        "gptResumeSummary":
        {
            "index" : 9,
            "value": "Re-wrtiting Resume Summary"
        },
        "gptResumeSummaryAnalysis":
        {
            "index" : 10,
            "value": "Re-writing Resume Summary Analysis"
        }
    }
    const jsonofresume = JSON.parse(resumejson)

    const [progress, setProgress] = useState(0)
    const [text, setText] = useState('')

    useEffect(() => {
        let newProgress = 0
        let newText = 'Processing resume just starting'
        
        for (const key in targetkeys) {
            if (jsonofresume[key]) {
                newProgress = targetkeys[key]['index'] * 10
                newText = targetkeys[key]['value']
            } else if (jsonofresume['summaryInfo'] && jsonofresume['summaryInfo'][key]) {
                newProgress = targetkeys[key]['index'] * 10
                newText = targetkeys[key]['value']
            }
        }

        setProgress(newProgress)
        setText(newText)
    }, [resumejson])

    return (
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', height: '3em', width: '100%' }}>
            <LinearProgress variant="determinate" value={progress} sx={{ position: 'absolute', width: '100%', height: '100%', borderRadius: '5px' }} />
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    zIndex: 1,
                }}
            >
                <Typography variant="body2" color="text.primary" textAlign="center">
                    {`${Math.round(progress)}%`}<br/>Can take up to 30 minutes
                </Typography>
            </Box>
        </Box>
    )
}

export default ResumeProcessingProgress