/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      email
      orders {
        nextToken
        __typename
      }
      resumereportcredits
      interviewruncredits
      hiregmeetingruncredits
      stripecustomerid
      stripetransactions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      email
      orders {
        nextToken
        __typename
      }
      resumereportcredits
      interviewruncredits
      hiregmeetingruncredits
      stripecustomerid
      stripetransactions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      email
      orders {
        nextToken
        __typename
      }
      resumereportcredits
      interviewruncredits
      hiregmeetingruncredits
      stripecustomerid
      stripetransactions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      createdAt
      resume {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      resumeid
      jobdescription {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      jobdescriptionid
      analysis {
        id
        orderid
        completed
        resumejson
        jobdescriptionjson
        tokensjson
        stepfunctionid
        createdAt
        updatedAt
        owner
        __typename
      }
      analysisid
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      targetrole
      servicelevel
      processingcomplete
      updatedAt
      customerOrdersId
      owner
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      createdAt
      resume {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      resumeid
      jobdescription {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      jobdescriptionid
      analysis {
        id
        orderid
        completed
        resumejson
        jobdescriptionjson
        tokensjson
        stepfunctionid
        createdAt
        updatedAt
        owner
        __typename
      }
      analysisid
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      targetrole
      servicelevel
      processingcomplete
      updatedAt
      customerOrdersId
      owner
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      createdAt
      resume {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      resumeid
      jobdescription {
        id
        filename
        orderid
        createdAt
        updatedAt
        owner
        __typename
      }
      jobdescriptionid
      analysis {
        id
        orderid
        completed
        resumejson
        jobdescriptionjson
        tokensjson
        stepfunctionid
        createdAt
        updatedAt
        owner
        __typename
      }
      analysisid
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      targetrole
      servicelevel
      processingcomplete
      updatedAt
      customerOrdersId
      owner
      __typename
    }
  }
`;
export const createResume = /* GraphQL */ `
  mutation CreateResume(
    $input: CreateResumeInput!
    $condition: ModelResumeConditionInput
  ) {
    createResume(input: $input, condition: $condition) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateResume = /* GraphQL */ `
  mutation UpdateResume(
    $input: UpdateResumeInput!
    $condition: ModelResumeConditionInput
  ) {
    updateResume(input: $input, condition: $condition) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteResume = /* GraphQL */ `
  mutation DeleteResume(
    $input: DeleteResumeInput!
    $condition: ModelResumeConditionInput
  ) {
    deleteResume(input: $input, condition: $condition) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobDescription = /* GraphQL */ `
  mutation CreateJobDescription(
    $input: CreateJobDescriptionInput!
    $condition: ModelJobDescriptionConditionInput
  ) {
    createJobDescription(input: $input, condition: $condition) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobDescription = /* GraphQL */ `
  mutation UpdateJobDescription(
    $input: UpdateJobDescriptionInput!
    $condition: ModelJobDescriptionConditionInput
  ) {
    updateJobDescription(input: $input, condition: $condition) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobDescription = /* GraphQL */ `
  mutation DeleteJobDescription(
    $input: DeleteJobDescriptionInput!
    $condition: ModelJobDescriptionConditionInput
  ) {
    deleteJobDescription(input: $input, condition: $condition) {
      id
      filename
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAnalysis = /* GraphQL */ `
  mutation CreateAnalysis(
    $input: CreateAnalysisInput!
    $condition: ModelAnalysisConditionInput
  ) {
    createAnalysis(input: $input, condition: $condition) {
      id
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      completed
      resumejson
      jobdescriptionjson
      tokensjson
      stepfunctionid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAnalysis = /* GraphQL */ `
  mutation UpdateAnalysis(
    $input: UpdateAnalysisInput!
    $condition: ModelAnalysisConditionInput
  ) {
    updateAnalysis(input: $input, condition: $condition) {
      id
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      completed
      resumejson
      jobdescriptionjson
      tokensjson
      stepfunctionid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAnalysis = /* GraphQL */ `
  mutation DeleteAnalysis(
    $input: DeleteAnalysisInput!
    $condition: ModelAnalysisConditionInput
  ) {
    deleteAnalysis(input: $input, condition: $condition) {
      id
      order {
        id
        createdAt
        resumeid
        jobdescriptionid
        analysisid
        targetrole
        servicelevel
        processingcomplete
        updatedAt
        customerOrdersId
        owner
        __typename
      }
      orderid
      completed
      resumejson
      jobdescriptionjson
      tokensjson
      stepfunctionid
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createStripeTransaction = /* GraphQL */ `
  mutation CreateStripeTransaction(
    $input: CreateStripeTransactionInput!
    $condition: ModelStripeTransactionConditionInput
  ) {
    createStripeTransaction(input: $input, condition: $condition) {
      id
      customerid
      stripeCustomerid
      transactionid
      transactiondatetime
      status
      paymentmethodid
      productid
      credits
      amount
      currency
      description
      receipturl
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateStripeTransaction = /* GraphQL */ `
  mutation UpdateStripeTransaction(
    $input: UpdateStripeTransactionInput!
    $condition: ModelStripeTransactionConditionInput
  ) {
    updateStripeTransaction(input: $input, condition: $condition) {
      id
      customerid
      stripeCustomerid
      transactionid
      transactiondatetime
      status
      paymentmethodid
      productid
      credits
      amount
      currency
      description
      receipturl
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteStripeTransaction = /* GraphQL */ `
  mutation DeleteStripeTransaction(
    $input: DeleteStripeTransactionInput!
    $condition: ModelStripeTransactionConditionInput
  ) {
    deleteStripeTransaction(input: $input, condition: $condition) {
      id
      customerid
      stripeCustomerid
      transactionid
      transactiondatetime
      status
      paymentmethodid
      productid
      credits
      amount
      currency
      description
      receipturl
      customer {
        id
        email
        resumereportcredits
        interviewruncredits
        hiregmeetingruncredits
        stripecustomerid
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
