export const listOrdersWithCustomer = /* GraphQL */ `
    query ListOrders(
        $filter: ModelOrderFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                createdAt
                targetrole
                servicelevel
                updatedAt
                customer {
                    email
                }
                resumeid
                jobdescriptionid
                processingcomplete
                analysisid
                analysis {
                    jobdescriptionjson
                    resumejson
                }
                __typename
            }
            nextToken
            __typename
        }
    }
`

export const getOrderDetails = /* GraphQL */ `
    query GetOrder($id: ID!) {
        getOrder(id: $id) {
            id
            createdAt
            targetrole
            servicelevel
            analysis {
                id
                completed
                resumejson
                jobdescriptionjson
                tokensjson
            }
            customer {
                id
                email
            }
        }
    }
`