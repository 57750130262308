import React from 'react'

import { createRoot } from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider, createTheme } from '@mui/material/styles'

import App from './App'

import '@aws-amplify/ui-react/styles.css'

import reportWebVitals from './reportWebVitals'

import ReactGA from "react-ga4";

const theme = createTheme({
    palette: {
        primary: {
            main: '#99AFD7',
        },
        secondary: {
            main: '#CCDBEE',
        },
    },
})

const GAMeasurementIdDev = "G-4NX1VPVR1B"
const GAMeasurementIdProd = "G-Y4LLRL707S"

if (process.env.REACT_APP_USER_BRANCH === 'prod') {
    ReactGA.initialize(GAMeasurementIdProd)
}
else {
    ReactGA.initialize(GAMeasurementIdDev)
}


const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </BrowserRouter>
)

reportWebVitals()