import React, { useState, useEffect } from 'react'
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Flex } from '@aws-amplify/ui-react'

import { styled } from '@mui/material/styles'


function TokensView({ data }) {
    const [tabularJobDescriptionData, setTabularJobDescriptionData] = useState([])
    const [jobdescriptionTotalCost, setJobDescriptionTotalCost] = useState([])
    const [tabularResumeData, setTabularResumeData] = useState([])
    const [resumeTotalCost, setResumeTotalCost] = useState([])
    const [jobdescriptionTotalTokens, setJobDescriptionTotalTokens] = useState({ prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 })
    const [resumeTotalTokens, setResumeTotalTokens] = useState({ prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 })
    const [totalCost, setTotalCost] = useState( 0 )

    useEffect(() => {
        async function processJson(jsondataToProcess) {
            const inputCost = .005 / 1000
            const outputCost = .015 / 1000

            const tabularData = Object.keys(jsondataToProcess).map(key => ({
                key,
                ...jsondataToProcess[key],
            })).sort((a, b) => a.key.localeCompare(b.key))
            

            const totalPromptTokens = Object.keys(jsondataToProcess).reduce((acc, key) => {
                return acc + jsondataToProcess[key].prompt_tokens
            }
            , 0)

            const totalCompletionTokens = Object.keys(jsondataToProcess).reduce((acc, key) => {
                return acc + jsondataToProcess[key].completion_tokens
            }
            , 0)

            const totalTokens = Object.keys(jsondataToProcess).reduce((acc, key) => {
                return acc + jsondataToProcess[key].total_tokens
            }
            , 0)

            const totalReturnTokens = {
                key: "Total Tokens",
                prompt_tokens: totalPromptTokens,
                completion_tokens: totalCompletionTokens,
                total_tokens: totalTokens}

            const totalCost = {
                key: "Total Cost",
                prompt_tokens: (totalPromptTokens * inputCost).toFixed(3),
                completion_tokens: (totalCompletionTokens * outputCost).toFixed(3),
                total_tokens: ((totalPromptTokens * inputCost) + (totalCompletionTokens * outputCost)).toFixed(3)
            }

            return {
                tabularData,
                totalReturnTokens,
                totalCost
            }
            //console.log("tab jd data:", tabularData)
        }

        async function processData() {
            const jdResponse = await processJson(data.jobDescription)
            const resumeResponse = await processJson(data.resume)

            setTabularJobDescriptionData(jdResponse.tabularData)
            setJobDescriptionTotalTokens(jdResponse.totalReturnTokens)
            setJobDescriptionTotalCost(jdResponse.totalCost)
            setTabularResumeData(resumeResponse.tabularData)
            setResumeTotalTokens(resumeResponse.totalReturnTokens)
            setResumeTotalCost(resumeResponse.totalCost)
            const totalTokenCost = (
                parseFloat(jdResponse.totalCost.total_tokens) + 
                parseFloat(resumeResponse.totalCost.total_tokens)
            ).toFixed(3) // You may also need to convert the result to a string with fixed decimal places
            setTotalCost(totalTokenCost)
        }

        processData()

    }, [data])

    const BoldedCell = styled(TableCell)(({ theme }) => ({
        fontWeight: 'bold'
    }))

    const FixedWidthCell = styled(TableCell)(({ theme }) => ({
        width: '240px', // Set your desired fixed width
        maxWidth: '240px', // Ensure it doesn't grow larger than the fixed width
    }))

    const FixedWidthBoldCell = styled(TableCell)(({ theme }) => ({
        width: '240px', // Set your desired fixed width
        maxWidth: '240px', // Ensure it doesn't grow larger than the fixed width
        fontWeight: 'bold'
    }))

    const FormattedNumberCell = ({ value }) => {
        const formattedNumber = value.toLocaleString()
    
        return (
            <TableCell>
                {formattedNumber}
            </TableCell>
        )
    }

    const FormattedBoldNumberCell = ({ value }) => {
        const formattedNumber = value.toLocaleString()
    
        return (
            <BoldedCell>
                {formattedNumber}
            </BoldedCell>
        )
    }

    return (
        <Box width="80%">
            <Box display="flex" sx={{mb: "30px"}}>
                <Typography variant="h3">Total Cost:</Typography>
                <Typography variant="h3">${totalCost}</Typography>
            </Box>
            <Typography variant="h4">Job Description Tokens</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <FixedWidthBoldCell>Key</FixedWidthBoldCell>
                            <BoldedCell>Prompt</BoldedCell>
                            <BoldedCell>Completion</BoldedCell>
                            <BoldedCell>Total</BoldedCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabularJobDescriptionData.map((row) => (
                            <TableRow key={row.key}>
                                <FixedWidthCell>{row.key}</FixedWidthCell>
                                <FormattedNumberCell value={row.prompt_tokens}></FormattedNumberCell>
                                <FormattedNumberCell value={row.completion_tokens}></FormattedNumberCell>
                                <FormattedNumberCell value={row.total_tokens}></FormattedNumberCell>
                            </TableRow>
                        ))}
                        <TableRow key={jobdescriptionTotalTokens.key}>
                            <FixedWidthBoldCell>Total Tokens</FixedWidthBoldCell>
                            <FormattedBoldNumberCell value={jobdescriptionTotalTokens.prompt_tokens}></FormattedBoldNumberCell>
                            <FormattedBoldNumberCell value={jobdescriptionTotalTokens.completion_tokens}></FormattedBoldNumberCell>
                            <FormattedBoldNumberCell value={jobdescriptionTotalTokens.total_tokens}></FormattedBoldNumberCell>
                        </TableRow>
                        <TableRow key={jobdescriptionTotalCost.key}>
                            <FixedWidthBoldCell>Total Cost</FixedWidthBoldCell>
                            <BoldedCell>${jobdescriptionTotalCost.prompt_tokens}</BoldedCell>
                            <BoldedCell>${jobdescriptionTotalCost.completion_tokens}</BoldedCell>
                            <BoldedCell>${jobdescriptionTotalCost.total_tokens}</BoldedCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h4">Resume Tokens</Typography>
            <TableContainer>
            <Table>
                    <TableHead>
                        <TableRow>
                            <FixedWidthBoldCell>Key</FixedWidthBoldCell>
                            <BoldedCell>Prompt</BoldedCell>
                            <BoldedCell>Completion</BoldedCell>
                            <BoldedCell>Total</BoldedCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabularResumeData.map((row) => (
                            <TableRow key={row.key}>
                                <FixedWidthCell>{row.key}</FixedWidthCell>
                                <FormattedNumberCell value={row.prompt_tokens}></FormattedNumberCell>
                                <FormattedNumberCell value={row.completion_tokens}></FormattedNumberCell>
                                <FormattedNumberCell value={row.total_tokens}></FormattedNumberCell>
                            </TableRow>
                        ))}
                        <TableRow key={resumeTotalTokens.key}>
                            <FixedWidthBoldCell>Total Tokens</FixedWidthBoldCell>
                            <FormattedBoldNumberCell value={resumeTotalTokens.prompt_tokens}></FormattedBoldNumberCell>
                            <FormattedBoldNumberCell value={resumeTotalTokens.completion_tokens}></FormattedBoldNumberCell>
                            <FormattedBoldNumberCell value={resumeTotalTokens.total_tokens}></FormattedBoldNumberCell>
                        </TableRow>
                        <TableRow key={resumeTotalCost.key}>
                            <FixedWidthBoldCell>Total Cost</FixedWidthBoldCell>
                            <BoldedCell>${resumeTotalCost.prompt_tokens}</BoldedCell>
                            <BoldedCell>${resumeTotalCost.completion_tokens}</BoldedCell>
                            <BoldedCell>${resumeTotalCost.total_tokens}</BoldedCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default TokensView