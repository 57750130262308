import React, { useState } from 'react'

import { Box } from '@mui/material'

import OrderedLpCompetency from './OrderedLpCompetency'
import SimpleBarChart from './SimpleBarChart'

function CompetencyView( {resumejson, jobdescriptionjson, viewtype } ) {

    return (
        <>
        <Box width="100%">
            <SimpleBarChart
                resumejson={resumejson}
                jobdescriptionjson={jobdescriptionjson}
                viewtype={viewtype}
                />
            {/* <OrderedLpCompetency data={resume} viewType="resume"/>
            <OrderedLpCompetency data={jobdescription} viewType="jobdescription"/> */}
        </Box>
        </>
    )
}

export default CompetencyView