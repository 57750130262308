import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import { Container, Typography } from '@mui/material'
import { Authenticator } from '@aws-amplify/ui-react'

function Signin( {user}) {
    const navigate = useNavigate()

    useEffect(() => {
        if (user) {
            navigate('/')
        }
    }, [user, navigate])

    return (
        <Container maxWidth="md" sx={{ mt: "20px" }}>
            <Typography align="center" variant="h3" sx={{mb: "20px"}}>
                Welcome to HiredUpon!
            </Typography>
            <Typography align="center" variant="h6" sx={{mb: "20px"}}>
                To start, you will first need to create an account.
            </Typography>
            
            {process.env.REACT_APP_USER_BRANCH === 'dev' ? (
                <Authenticator>
                </Authenticator>
            ) : (
                <Authenticator>
                </Authenticator>
            )}
        </Container>
    )
}

export default Signin