import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { Typography, Box } from '@mui/material'
import { styled } from '@mui/system'

const acceptedMimeTypes = [
    'application/pdf', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
    'text/plain'
]

const acceptedFileType = "*.pdf, *.docx, *.txt"

const DropzoneContainer = styled(Box)(({ theme, uploaded }) => ({
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '50%',
    margin: theme.spacing(2,0),
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: uploaded ? theme.palette.secondary.main : '#e8f5e9', 
}))

function FileDrop ({ onDrop, accept, fileType }) {
    const [uploaded, setUploaded] = useState(false)
    const [filename, setFilename] = useState('')

    const fileTypes = { "resume": "Resume", "jobdescription": "Job Description"}
    const fileTypeText = fileTypes[fileType]

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: acceptedFiles => {
            if (uploaded) return

            const file = acceptedFiles[0]

            // Additional MIME type check to handle discrepancies
            if (!acceptedMimeTypes.includes(file.type)) {
                alert(`Invalid file type. Please upload a .pdf, .docx, or .txt file.`)
                return
            }

            setUploaded(true)
            setFilename(file.name)
            onDrop(acceptedFiles)
            
        },
        accept,
        maxFiles: 1
    })

    return (
        <DropzoneContainer {...getRootProps()} uploaded={uploaded ? 1 : 0}>
            <input {...getInputProps()} />
            <Typography>
                {uploaded ? `${fileTypeText}: ${filename} uploaded successfully` : 
                    (isDragActive ? `Drop the ${fileTypeText} here ...` : `Drag and drop a ${fileTypeText} file here, or click to select a ${fileTypeText}. Accepted file types: ${acceptedFileType}`)}
            </Typography>
        </DropzoneContainer>
    )
}

export default FileDrop