import React from 'react'
import {Typography, Box } from '@mui/material'

import DisplayOrderedLpCompetency from './DisplayOrderedLpCompetency'

function OrderedLpCompetency( { data, viewType }) {
    const sortedCompetenciesKeys = Object.keys(data.orderedCompetencies).sort((b,a) => data.orderedCompetencies[b].rank - data.orderedCompetencies[a].rank)
    const sortedLeadershipPrinciplesKeys = Object.keys(data.orderedLeadershipPrinciples).sort((b,a) => data.orderedLeadershipPrinciples[b].rank - data.orderedLeadershipPrinciples[a].rank)

    return (
        <Box sx={{width:"100%", px:"80px"}}>
            <DisplayOrderedLpCompetency orderedData={data.orderedCompetencies} sortedKeys={sortedCompetenciesKeys} dataType="Competencies" viewType={viewType} />
            <DisplayOrderedLpCompetency orderedData={data.orderedLeadershipPrinciples} sortedKeys={sortedLeadershipPrinciplesKeys} dataType="Leadership Principles" viewType={viewType} />
        </Box>
    )
}

export default OrderedLpCompetency