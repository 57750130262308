import React from 'react'
import { Button, Card, CardMedia, CardContent, Typography, CardActions, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

function ResumeLicenseProductCard ({ productDetails, purchaseClick, ctaText }) {
    const theme = useTheme()
    const { product, prices } = productDetails
    const price = (prices.data[0].unit_amount / 100).toFixed(2)
    const currency = prices.data[0].currency.toUpperCase()

    // console.log("ResumeLicenseProductCard productDetails: ", productDetails)

    return (
        <Card raised sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            maxWidth: { xs: 280, sm: 560 },
            margin: 'auto',
            width: '100%'
        }}>
            <CardMedia
                component="img"
                sx={{ width: 280, height: 280 }} // Fixed size for image
                image={product.images[0]}
                alt={product.name}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {product.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {product.description}
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                        {`${price} ${currency}`}
                    </Typography>
                </CardContent>
                <CardActions sx={{ flexGrow: 1 }}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth sx={{fontWeight: "bold", color: "black"}}
                        onClick={() => purchaseClick(prices.data[0].id)}
                    >
                        {ctaText}
                    </Button>
                </CardActions>
            </Box>
        </Card>
    )
}

export default ResumeLicenseProductCard