import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Box, AppBar, Toolbar, Tooltip, Avatar, Typography, Button } from '@mui/material'
import { styled } from '@mui/system'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import { Auth, API, graphqlOperation } from 'aws-amplify'
import { listOrders, listCustomers } from '../graphql/queries'



const StyledAppBar = styled(AppBar)({
    flexGrow: 1,
})

const StyledTitle = styled(Typography)({
    flexGrow: 1,
})

const StyledButton = styled(Button)({
    color: 'black',
    fontWeight: 'bold',
    height: '3em', // Adjust height as needed to fit two lines with extra space
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1.5em', // Increase line height for more space between lines
    textDecoration: 'none', // Remove text underline for the button itself
})

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: theme.palette.primary.main,
        fontSize: '1.1em',
        color: 'black',
        border: `3px solid ${theme.palette.secondary.main}`,
    },
}))

function Navbar ({ user, signOut }) {
    const navigate = useNavigate()
    const [resumeReportsCount, setResumeReportsCount] = useState(0)
    const [customerCredits, setCustomerCredits] = useState(0)

    useEffect(() => {
        async function fetchOrdersAndCredits() {
            if (user) {
                const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || []
                const isAdmin = groups.includes('Admin')
        
                try {
                    const orderData = await API.graphql(graphqlOperation(listOrders))
                    setResumeReportsCount(orderData.data.listOrders.items.length)
                } catch (err) {
                    console.error('Error fetching orders:', err)
                }

                if (!isAdmin){
                    try {
                        const customerData = await API.graphql(graphqlOperation(listCustomers))
                        setCustomerCredits(customerData.data.listCustomers.items[0].resumereportcredits)
                    } catch (err) {
                        console.error('Error fetching customer credits:', err)
                    }
                }
                else {
                    setCustomerCredits('Unlimited')
                }
            }
        }

        fetchOrdersAndCredits()
    }, [user])

    async function handleSignOut () {
        try {
            await Auth.signOut()
            navigate('/')
        } catch (error) {
            console.log('error signing out: ', error)
        }
    }

    async function handleNavigateClick(destination){
        navigate(destination)
    }

    const userInfo = (
        <Box>
            <div>{user && user.attributes && user.attributes.email}</div>
            <div>Resume Reports: {resumeReportsCount}</div>
            <div>Credits Available: {customerCredits}</div>
        </Box>
    )

    return (
        <StyledAppBar position="static">
            <Toolbar>
                <a href="/">
                        <img
                        src="/images/HiredUpon_Logo.png"
                        alt="HiredUpon Logo"
                        width="100"
                        height="100"
                    />
                </a>
                <div style={{ flexGrow: 1 }} /> {/* Add this spacer */}
                {user ? (
                    // Menu for logged-in users
                    <>
                        {/* <Link to="/newresumeorder">
                            <StyledButton color="inherit">New Order</StyledButton>
                        </Link> */}
                        <StyledButton color="inherit" onClick={()=>handleNavigateClick('/resumereports')}>Resume<br/>Reports</StyledButton>
                        <StyledButton color="inherit" onClick={handleSignOut}>Sign Out</StyledButton>  
                        <StyledTooltip
                            title={userInfo}
                            placement="bottom-end"
                            arrow
                        >
                            <Avatar sx={{ bgcolor: "primary.main", border: "2px solid black" }}>
                                <AccountCircleIcon color={"#CCDBEE"}/>
                            </Avatar>
                        </StyledTooltip>
                    </>
                ) : (
                    // Menu for non-logged-in users
                    <>
                        <StyledButton color="inherit" onClick={()=>handleNavigateClick('/')}>Home</StyledButton>
                        <StyledButton color="inherit" onClick={()=>handleNavigateClick('/signin')}>Sign In</StyledButton>
                    </>
                )}
            </Toolbar>
        </StyledAppBar>
    )
}

export default Navbar