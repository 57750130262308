import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Auth, API, graphqlOperation } from 'aws-amplify'
import { customerByCustomerEmail } from '../graphql/queries'

import { Typography, Container, Box } from '@mui/material'

import Testimonials from '../components/Testimonials'
import ResumeLicenseFaq from '../components/ResumeLicenseFaq'
import FeatureHighlights from '../components/FeatureHighlights'
import ResumeReportCta from '../components/ResumeReportCta'

const data = {
    "bullet1" :
        {
            "originalText": "Responsible for product management for all Kindle Digital Reader apps on all platforms (eInk, Fire Tablet, iOS, Android, Windows and Windows Phone, and Cloud Reader). Kindle Reader apps have 10s of millions of monthly active customers globally, and my products drove multi-billion $$ in annual digital content revenue.",
            "newText": "Managed product development for all Kindle Digital Reader apps across various platforms, driving multi-billion dollar annual digital content revenue and demonstrating a strong sense of responsibility and ownership.",
            "metricsText": "\"Managed product development for all Kindle Digital Reader apps across various platforms, driving multi-billion dollar annual digital content revenue...\" - It would be beneficial to quantify the increase in digital content revenue under the candidate's management. For example, they could specify the percentage growth in revenue year-over-year or the absolute increase in revenue." 
        },
    "bullet2" :
    {
        "originalText": "Recruited, built, and managed a new team of product managers, business manager, data analyst, and technical account manager, with indirect leadership of multiple engineering teams across multiple operating system platforms. Team had 4 top tier performers, with only a single attrition due to employee dissatisfaction during tenure in role.",
        "newText": "Built and led a new team of product managers, business managers, data analysts, and technical account managers, showcasing strong leadership skills and resulting in a team with four top performers and minimal attrition due to dissatisfaction.",
        "metricsText": "\"Built and led a new team of product managers, business managers, data analysts, and technical account managers...\" - The candidate could provide metrics on team performance, such as the number of successful projects completed, the increase in productivity, or the decrease in attrition rate."
    },
    "bullet3" :
    {
        "originalText": "Refocused development teams around cross platform ecosystem feature parity to ensure our best customers were having consistent experiences on all devices on which they read.        ",
        "newText": "Integrated a data-focused discipline into the organization, simplifying business reviews and centering analysis on key metrics in the customer funnel, leading to a significant increase in new customer acquisition.",
        "metricsText": "\"Integrated a data-focused discipline into the organization, simplifying business reviews and centering analysis on key metrics in the customer funnel...\" - The candidate could specify the impact of this data-focused approach. For instance, they could mention the increase in new customer acquisition or the improvement in customer retention rate."
    },
    
}

const keys = Object.keys(data)
const randomKey = keys[Math.floor(Math.random() * keys.length)]

const bulletToUse = data[randomKey]

function LandingPage ( ) {
    //This is for tracking using ReactGA
    const location = useLocation()

    const [showCta, setShowCta] = useState(true)

    const productIdList = process.env.REACT_APP_RESUME_LICENSE_IDS.split(",")

    // console.log("productIdList: ", productIdList)

    //useEffect to to get customerid from user object, get customer object from grapqhl, check Customer.resumecredits != 0, set showCta to true
    useEffect(() => {
        async function checkUserCredits() {
            try {
                const user = await Auth.currentAuthenticatedUser()
                const email = user.attributes.email

                //checl to see if user is an admin
                const userGroups = user.signInUserSession.accessToken.payload["cognito:groups"]
                if (userGroups && userGroups.includes("Admin")) {
                    setShowCta(false)
                }
                else {
                    const customerData  = await API.graphql(graphqlOperation(customerByCustomerEmail, {
                        email: email
                    }))

                    const customer = customerData.data.customerByCustomerEmail.items[0]
                    
                    if (customer.resumereportcredits > 0) {
                        setShowCta(false)
                    }
                }
            } catch (error) {
                console.log("Error checking user data: ", error)
            }
        }
        checkUserCredits()
    }, [])

    return (
        <Container maxWidth="lg">
            <Box my={4} textAlign="center">
                <Typography variant="h4" component="h5" gutterBottom>
                    Speak to Companies in Their Language
                </Typography>
                <Typography variant="h5" component="h5" gutterBottom>
                    Customize your resume to highlight the experience companies seek using the tone they use
                </Typography>
                <FeatureHighlights />
                { showCta ? 
                    productIdList.map((productId) => {
                        return <ResumeReportCta key={productId} productId={productId} />
                    }) 
                    :
                    null
                }
                <Testimonials />
                <ResumeLicenseFaq />
            </Box>
        </Container>
    )   
}

export default LandingPage