import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material'

import { API, Auth, graphqlOperation, Storage } from 'aws-amplify'

import { resumeByOrderId, analysisByOrderId, jobDescriptionByOrderId, customerByCustomerEmail } from '../graphql/queries'
import { deleteResume, deleteAnalysis, deleteJobDescription, deleteOrder } from '../graphql/mutations'
import { listOrdersWithCustomer } from '../graphql/customqueries'

import ResumeReportCard from '../components/ResumeReportCard'

import '../css/Orders.css'

function ResumeReports( { user }) {
    const navigate = useNavigate()

    const [isAdmin, setIsAdmin] = useState(false)   
    const [creditsAvailable, setCreditsAvailable] = useState(false)
    const [numCredits, setNumCredits] = useState(0)
    
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(0)
    
    //Note to future me: have consciously removed tokens for pagination because going over 100 should realistically never happen
    const cardsPerPage = 100
    
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [isDownloading, setIsDownloading] = useState(false)
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)

    useEffect(() => {
        async function validatecredits (user) {
            const email = user.attributes.email
            const response  = await API.graphql(graphqlOperation(customerByCustomerEmail, {
                email: email
            }))
            const customer = response.data.customerByCustomerEmail.items
    
            if (customer.length === 0) {
                console.log("ERROR: something went wrong verifying customer in Orders.js")
                return false
            }
            else if (customer.length === 1) {
                if (customer[0].resumereportcredits === 0 || customer[0].resumereportcredits === null) {
                    //console.log("LOGGING: customer does not have Resume Report credits")
                    return false
                }
            }
            else {
                //TODO: NEED TO HANDLE THIS BETTER - base on Admin group vs not
                console.log("ERROR: somehow we got more than 1 customer when verifying customer in Orders.js")
                return false
            }

            setNumCredits(customer[0].resumereportcredits)                
            return true
        }

        async function checkLicenseAndFetchOrders() {
            const user = await Auth.currentAuthenticatedUser()
            const groups = user.signInUserSession.accessToken.payload["cognito:groups"]

            if (!user) {
                navigate('/')
            }  
            else {
                if (groups && groups.includes("Admin")) {
                    setIsAdmin(true) // Set the isAdmin state
                }
                else {
                    const creditsremain = await validatecredits(user)
                    setCreditsAvailable(creditsremain)
                    isCreateNewOrderActive(creditsremain)
                }
                fetchOrders()
            }
        }   
        checkLicenseAndFetchOrders()

        const intervalId = setInterval(() => {
            fetchOrders()
        }, 60000) // 60000 ms = 1 minute

        // Clear interval on component unmount
        return () => clearInterval(intervalId)
    }, [page, user])

    function handleDeleteModelOpen (id) {
        setDeleteId(id)
        setDeleteModalOpen(true)
    }

    function handleClose () {
        setDeleteModalOpen(false)
    }

    function handleConfirmDelete () {
        handleDelete(deleteId)
        setDeleteModalOpen(false)
    }

    async function fetchOrders() {
        //console.log('Fetching orders...')
        try {
            const result = await API.graphql(graphqlOperation(listOrdersWithCustomer, {
                limit: cardsPerPage,
            }))

            //console.log(`testing the fetchorders of ResumeReport: ${result.data.listOrders.items}`)
    
            let orders = result.data.listOrders.items.map(order => {
                // Check if analysis exists and has the required properties
                const analysisExists = order.analysis && order.analysis.jobdescriptionjson && order.analysis.resumejson

                if (order.servicelevel === 'basic' ) {
                    return {
                        ...order,
                        companyName: "No Company Specified"
                    }
                }
                else if (!analysisExists) {
                    return {
                        ...order,
                        companyName: "Processing Order"
                    }
                } 
                else {
                    try {
                        const companyName = JSON.parse(order.analysis?.jobdescriptionjson ?? '{}').jobDescriptionCompanyInfo?.companyName ?? "Processing Order"
                        return {
                            ...order,
                            companyName
                        }
                    } catch (error) {
                        console.error("Error parsing jobdescriptionjson: ", error)
                        return {
                            ...order,
                            companyName: "Error Processing - Please Contact Support"
                        }
                    }
                }
            })
            
            //console.log('Orders:', orders)
    
            // Sort the orders by companyName
            orders.sort((a, b) => a.companyName.localeCompare(b.companyName))
    
            setOrders(orders)
        } catch (error) {
            console.error('Error fetching orders:', error)
        }
    }

    async function handleDownlad(id) {
        setIsDownloading(true)
        setDownloadDialogOpen(true)

        const stage = process.env.REACT_APP_USER_BRANCH 

        //console.log(`stage is: ${stage}, id is: ${id}`)

        let response
        try {
            response = await API.get('writeResumeReport-bigtechivy', `/downloadreport/${id}/${stage}`, {})
            console.log('Response: ', response)
        } catch (err) {
            console.error(err)
        } finally {
            if (response.status === 'success'){
                // console.log('TMP LOGGING: This is to trigger redeploy - We have a file to download')
                const url = response.url
                window.open(url, '_blank')
            }
            else {
                console.log('No file to download because body.status !== success')
            }
            setIsDownloading(false)
            setDownloadDialogOpen(false)
        }
    }

    async function handleDelete(id) {
        try {
            const resumeData = await API.graphql(graphqlOperation(resumeByOrderId, { orderid: id }))
            if (resumeData.data.resumeByOrderId.items.length > 0) {
                const resumeId = resumeData.data.resumeByOrderId.items[0].id
                await API.graphql(graphqlOperation(deleteResume, { input: { id: resumeId } }))
            }

            const jobdescriptionData = await API.graphql(graphqlOperation(jobDescriptionByOrderId, { orderid: id }))
            if (jobdescriptionData.data.jobDescriptionByOrderId.items.length > 0) {
                const jobdescriptionId = jobdescriptionData.data.jobDescriptionByOrderId.items[0].id
                await API.graphql(graphqlOperation(deleteJobDescription, { input: { id: jobdescriptionId } }))
            }

            const analysisData = await API.graphql(graphqlOperation(analysisByOrderId, { orderid: id }))
            if (analysisData.data.analysisByOrderId.items.length > 0) {
                const analysisId = analysisData.data.analysisByOrderId.items[0].id
                if (analysisId) { // Ensure that analysisId is not null
                    await API.graphql(graphqlOperation(deleteAnalysis, { input: { id: analysisId } }))
                }
            }

            // Delete order
            await API.graphql(graphqlOperation(deleteOrder, { input: { id } }))
    
    
            // Delete all of the sub files from S3
            const list = await Storage.list(`${id}/`)

            await Promise.all(list.results.map(item => Storage.remove(item.key)))

            // Refresh the orders list
            fetchOrders()

        } catch (error) {
            console.error(`Error deleting order id ${id} with error: ${error}`)
        }
    }

    function isCreateNewOrderActive(creditsvailable){

    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h4" sx={{ my: 2 }}>Resume Reports</Typography>
                {isAdmin ? (
                    <Button
                        sx={{ minWidth: 'auto', background: "#99AFD7", fontWeight: "bold", padding: "10px" }}
                        variant="contained"
                        onClick={() => navigate('/newresumeorder')}
                    >
                        Create New
                    </Button>
                ) : creditsAvailable ? (
                    <Button
                        sx={{ minWidth: 'auto', background: "#99AFD7", fontWeight: "bold", padding: "10px" }}
                        variant="contained"
                        onClick={() => navigate('/newresumeorder')}
                    >
                        Create New<br/>({numCredits > 0 ? numCredits : 0} Credits Remaining)
                    </Button>
                ) : (
                    <Button
                        sx={{ minWidth: 'auto', background: "#99AFD7", fontWeight: "bold", padding: "10px" }}
                        variant="contained"
                        onClick={() => navigate('/resumelicensecheck')}
                    >
                        Purchase Credits<br/>({numCredits} Credits Remaining)
                    </Button>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {orders.map(order => (
                    <ResumeReportCard
                        key={order.id}
                        order={order}
                        onDownload={handleDownlad}
                        onDelete={handleDeleteModelOpen}
                    />
                ))}
            </Box>

            
            <Dialog
                open={deleteModalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this order? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirm Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={downloadDialogOpen}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Resume Report Being Generated"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The file will download automatically. You may need to enable pop-ups for this site and re-download.
                    </DialogContentText>
                    <CircularProgress sx={{ m: 'auto', display: 'flex' }}/>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ResumeReports