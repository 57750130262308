import React from 'react'
import { Box, Typography, Grid, Card, CardContent, useTheme } from '@mui/material'

const featuresAll = [
    {
        title: 'Use Machines to Beat Machines',
        description: 'Outsmart the bots! Our AI-driven approach tailors your resume to glide past Applicant Tracking Systems and into the hands of hiring managers.'
    },
    {
        title: 'Precision Matching',
        description: 'Our system analyzes job descriptions and optimizes your resume to match. Speak their language, showcase your fit, and stand out in a sea of applicants.'
    },
    {
        title: 'Dynamic Customization',
        description: 'Easily and quickly adapt your resume for each job application. Our software will highlight the relevant skills and experience that resonate with the specific role you\'re targeting.'
    },
    {
        title: 'Real-Time Analysis',
        description: 'Get immediate insights on how well your resume matches up against your dream job\'s requirements. Make data-driven decisions to improve your chances.'
    },
    {
        title: 'Key Competencies Focus',
        description: 'Elevate key competencies that employers are seeking. Our tool ensures your strengths are front and center, making them impossible to overlook.'
    },
    {
        title: 'Match Their Language',
        description: 'Adjust the tone of your resume to align with company culture. Our AI detects the subtleties and helps you present yourself as the perfect cultural fit.'
    },
    {
        title: 'Leadership Lens',
        description: 'Aiming for a leadership role? Our system emphasizes your leadership principles and experiences, positioning you as an ideal candidate for management.'
    },
    {
        title: '24/7 Resume Assistant',
        description: 'Your personal AI resume assistant is ready anytime you need. Update and refine your resume on-the-go, ensuring you\'re always prepared for opportunity.'
    },
    {
        title: 'Success Stories',
        description: 'Don\'t just take our word for it. See how others have landed their dream jobs with our system. Real examples, real results.'
    },
    {
        title: 'Cost-Effective Campaigns',
        description: 'Save time and money. Our service eliminates the need for costly resume writers and gives you the tools to take control of your job search.'
    },
    {
        title: 'Submit Resumes Faster',
        description: 'When a job listing goes live, time is critical. Get changes in minutes to adapt your resume to that job description and highlight the right skills/experience using their own language.'
    },
    {
        title: 'One Credit, One Report',
        description: 'Our pricing is simple; one credit required for each Resume Report. No subscriptions and no hidden fees. Discounting applied when purchasing multiple credits.'
    },

]

const gptSuggestedOrdering = [0, 10, 6, 5, 4, 11, 6, 7, 9, 8, 2, 1]
const highlightsToShow = 6
const features = gptSuggestedOrdering.slice(0, highlightsToShow).map(index => featuresAll[index])

function FeatureHighlights() {
    const theme = useTheme()

    return (
        <Box my={4}>
            <Typography variant="h4" gutterBottom textAlign="center">
                Resume Revolution
            </Typography>
            <Grid container spacing={2}>
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card raised sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{
                                flexGrow: 1,
                                backgroundColor: theme.palette.grey[100],
                                ':last-child': { pb: 2 }
                            }}>
                                <Typography variant="h6" sx={{ pl: "8px", fontWeight: 'bold', color: "black", backgroundColor: theme.palette.primary.main }} gutterBottom>
                                    {feature.title}
                                </Typography>
                                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                    {feature.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default FeatureHighlights