import React from 'react'

import { Typography, Box } from '@mui/material'


function SummaryView( {data} ) {

    return (
        <Box sx={{ width: '100%', px:"80px" }}>
            <Typography variant="h6" fontWeight={"bold"} sx={{fontSize: '1.1rem'}}>Your Resume Summary:</Typography>
            <Typography variant="body1" sx={{mb: "15px", mx: "25px"}}>{
                data.summaryInfo ? 
                    (data.summaryInfo.summaryText?.length > 0 ? data.summaryInfo.summaryText : "None detected in resume"  ):
                    "Still Processing"
                }
            </Typography>
            <Typography variant="h6" fontWeight={"bold"} sx={{fontSize: '1.1rem'}}>Porposed Resume Summary:</Typography>
            <Typography variant="body1" sx={{mb: "15px", mx: "25px"}}>{
                data.summaryInfo ? data.summaryInfo.gptResumeSummary : "Still Processing"
                }
            </Typography>
            <Typography variant="h6" fontWeight={"bold"} sx={{fontSize: '1.1rem'}}>Analysis of your Resume Summary:</Typography>
            <Typography variant="body1" sx={{mb: "15px", mx: "25px"}}>{
                data.summaryInfo ? data.summaryInfo.gptResumeSummaryAnalysis : "Still Processing"
            }
            </Typography>
        </Box>
    )
}

export default SummaryView