import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, TextField, RadioGroup, FormControlLabel, Radio, Typography, Box } from '@mui/material'

import { v4 } from 'uuid'

import { Auth, Storage, API, graphqlOperation } from 'aws-amplify'

import { customerByCustomerEmail } from '../graphql/queries'
import { createCustomer, createOrder, createResume, createJobDescription } from '../graphql/mutations'

import FileDrop from './FileDrop'

function ResumeOrderForm ( { user } ) {
    const [orderId, setOrderId] = useState(v4())
    const [email, setEmail] = useState('')
    const [emailEditable, setEmailEditable] = useState(true)
    const [emailError, setEmailError] = useState(false)
    const [service, setService] = useState('pro')
    const [resumeFile, setResumeFile] = useState()
    const [jobDescriptionFile, setJobDescriptionFile] = useState()
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [jobTitle, setJobTitle] = useState('')
    const [resetKey, setResetKey] = useState(Date.now())

    const navigate = useNavigate()

    useEffect(() => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
        const isEmailValid = emailRegex.test(email)
        
        async function insertEmailForLoggedInUser() {
            const user = await Auth.currentAuthenticatedUser()
            //check if the user is in Admin Group
            const groups = user.signInUserSession.accessToken.payload["cognito:groups"]
            if (groups && groups.includes("Admin")) {
                setEmailEditable(true)
            } else {
                setEmail(user.attributes.email)
            }
        }

        insertEmailForLoggedInUser()

    
        if (service === 'basic') {
            setIsSubmitDisabled(!isEmailValid || !resumeFile || !jobTitle)
        } else {
            setIsSubmitDisabled(!isEmailValid || !resumeFile || !jobDescriptionFile)
        }
    }, [email, service, resumeFile, jobDescriptionFile, jobTitle])

    const accept = {
        'application/pdf': ['.pdf'],
        'text/plain': ['.txt'],
        'application/msword': ['.docx'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    }

    const onDropResume = useCallback(async acceptedFiles => {
        const file = acceptedFiles[0]
        
        const result = await Storage.put(`${orderId}/resume/${file.name}`, file, {
            contentType: file.type
        })
        
        //const fileUrl = await Storage.get(result.key)

        setResumeFile({"filename": file.name })

        console.log(`Result: ${result}, filename: ${file.name}`)
    }, [orderId])
    
    const onDropDescription = useCallback(async acceptedFiles => {
        const file = acceptedFiles[0]
    
        const result = await Storage.put(`${orderId}/jobdescription/${file.name}`, file, {
            contentType: file.type
        })

        //const fileUrl = await Storage.get(result.key)
    
        setJobDescriptionFile({"filename": file.name })

        console.log(`Result: ${result}, filename: ${file.name}`)
    }, [orderId])

    function handleEmailChange (e) {
        setEmail(e.target.value)
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
        setEmailError(!emailRegex.test(e.target.value))
    }

    async function onSubmit (event) {
        event.preventDefault()
    
        let customerOrdersId = ''
        // Check if customer email exists
        async function fetchCustomerByEmail (email) {
            try {
                const response = await API.graphql(graphqlOperation(customerByCustomerEmail, {
                    email: email
                }))
                const customer = response.data.customerByCustomerEmail.items
                console.log('Customer from fetch: ', customer)
                return customer
            } catch (error) {
                console.error('Error fetching customer by email:', error)
                return []
            }
        }

        const customer = await fetchCustomerByEmail(email)
        //console.log('Customer from onSubmit: ', customer)

        if (customer.length === 0) {
            //console.log(`customer with ${email} not found; creating new`)
            
            const newCustomer = {
                id: v4(),
                email: email,
                resumereportcredits: 0,
            }
            await API.graphql(graphqlOperation(createCustomer, { input: newCustomer }))
            //console.log('Customer: ', newCustomer)
            customerOrdersId = newCustomer.id
        }
        else if (customer.length === 1) {
            //console.log(`1 customer with ${email} found`)
            customerOrdersId = customer[0].id
        }
        else if (customer.length > 1) {
            //console.log(`more than 1 customer with email ${email} found, but this should never happen`)
            customerOrdersId = customer[0].id
        }
    
        // Create new resume
        const newResume = {
            orderid: orderId,
            filename: resumeFile.filename,
        }
        await API.graphql(graphqlOperation(createResume, { input: newResume }))

        //console.log('Resume: ', newResume)
    
        // If service is not basic, create a new job description
        if (service !== 'basic') {
            const newJobDescription = {
                orderid: orderId,
                filename: jobDescriptionFile.filename
            }
            await API.graphql(graphqlOperation(createJobDescription, { input: newJobDescription }))
            console.log('Job Description: ', newJobDescription)
        }

        // moved this to last operation AFTER removing the constraints on Resume and JobDescription that required an Order.
        // This is because the OrderID is now generated in the form and not in the database, and we have a Dynamo trigger that has potential for race conditions if we don't do this.
        // Create new order
        const newResumeOrder = {
            id: orderId,
            //createdAt: new Date().toISOString(),
            customerOrdersId: customerOrdersId,
            servicelevel: service,
            processingcomplete: false
        }
        if (service === 'basic') {
            newResumeOrder.targetrole = jobTitle
        }
        await API.graphql(graphqlOperation(createOrder, { input: newResumeOrder }))
    
        //console.log('Order: ', newResumeOrder)

        //console.log(`Database updated for ${email} using ${service} service level`)
        navigate('/resumereports')
    }

    async function deleteFiles (path) {
        //console.log(`entering deleteFiles with path ${path}`)
        const { results: files } = await Storage.list(path)

        //console.log('files results from S3: ', files)
    
        await Promise.all(files.map(async (file) => {
            if (file.key.endsWith('/')) {
                // This is a folder, need to delete its contents first
                //console.log('Deleting folder', file.key)
                await deleteFiles(file.key)
            } else {
                // This is a file, delete it
                //console.log('Deleting file', file.key)
                await Storage.remove(file.key)
            }
        }))
    
        // Delete the folder itself
        await Storage.remove(path)
    }

    function startOver () {
        setEmail('')
        setService('')
        setResumeFile(null)
        setJobDescriptionFile(null)
        setJobTitle('')
        
        if (resumeFile || jobDescriptionFile) {
            deleteFiles(`${orderId}/`).then(() => {
                console.log('Files deleted')
                setOrderId(v4())
                setResetKey(Date.now())
            })}
        else {
            console.log('No files to delete')
            setOrderId(v4())
            setResetKey(Date.now())
        }
    }

    return (
        <form onSubmit={onSubmit}>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: "10px" }}>
                <Typography variant="body" sx={{my: "5px"}}>OrderID: {orderId}</Typography>
            </Box> */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: "10px" }}>
                <Typography variant="h5" sx={{width: "16ch", marginRight: "5px"}}>Email of Customer:</Typography>
                <TextField 
                    label="Email" 
                    value={email} 
                    error={emailError} 
                    helperText={emailError ? "Please enter a valid email" : ""} 
                    sx={{width: '40ch'}} 
                    onChange={handleEmailChange}
                    disabled={!emailEditable} // Add this line
                />
            </Box>
            <Box sx={{ width: "1000px", marginLeft: "40px", my: "20px" }}>
                <FileDrop key={resetKey} onDrop={onDropResume} accept={accept} fileType="resume"/>
            </Box>
            {service === 'basic' ? (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: "10px" }}>
                    <Typography variant="h5" sx={{width: "16ch", marginRight: "5px"}}>Job Description:</Typography>
                    <TextField label="Job Title" value={jobTitle} sx={{marginBottom: "20px", width: "40ch"}} onChange={(e) => setJobTitle(e.target.value)} />
                </Box>
            ) : (
                <Box sx={{  width: "1000px", marginLeft: "40px", marginBottom: "20px" }}>
                    <FileDrop key={resetKey} onDrop={onDropDescription} sx={{marginBottom: "20px"}} accept={accept} fileType="jobdescription" />
                </Box>
            )}
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    width: '550px', 
                    mt: "40px",
                    mb: "20px"
                }}
            >
                <Button variant="contained" sx={{mx:"10px", backgroundColor: (theme) => theme.palette.secondary.main}} onClick={startOver}>Start Over</Button>
                <Button variant="contained" type="submit" disabled={isSubmitDisabled} sx={{mx:"10px"}}>Create Order</Button>
            </Box>
        </form>
    )
}

export default ResumeOrderForm