import React from 'react'
import { useNavigate } from 'react-router-dom'

import ReactGA from 'react-ga4'

import { Paper, Card, Box, CardContent, Typography, CardActions, Button, Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Visibility from '@mui/icons-material/Visibility'

import moment from 'moment'

import ResumeProcessingProgress from './ResumeProcessingProgress'

function ResumeReportCard({ order, onDownload, onDelete }) {
    const navigate = useNavigate()

    const paperStyle = {
        padding: '4px', // Adds padding around the card
        margin: '10px', // Margin around the paper
        boxShadow: '0px 6px 12px rgba(0,0,0,0.15)', // Stronger shadow for more depth
        width: '330px',
        minHeight: '180px',
    }

    const cardStyle = {
        backgroundColor: order.processingcomplete ? '#e3f2fd' : '#ffebee',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    }

    function handleClick() {
        //`event` method is called with: event name, category, label, value, and non-interaction flag.
        ReactGA.event({
            category: 'button_click',
            action: 'click',
            label: `ResumeReportCard: Customer clicked on their card order.id: ${order.id}`,
            value: 0,
            nonInteraction: false
        })
        navigate(`/OrderDetail/${order.id}`)
    }

    return (
        <Paper sx={paperStyle}>
            <Card
                sx={cardStyle}
                onMouseEnter={order.processingcomplete ? e => e.currentTarget.style.cursor = 'pointer': null}
                onMouseLeave={order.processingcomplete ? e => e.currentTarget.style.cursor = 'default': null}
            >
                <CardContent
                    onClick={order.processingcomplete ? handleClick : null}
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }
                }>
                    <Box>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.1em' }} component="div">
                            {order.servicelevel === 'basic' ? 
                                order.targetrole
                                :
                                JSON.parse(order.analysis?.jobdescriptionjson ?? '{}').jobDescriptionCompanyInfo?.companyRole ==='Not specified' ? 'Role: Not Specified' : JSON.parse(order.analysis?.jobdescriptionjson ?? '{}').jobDescriptionCompanyInfo?.companyRole ?? 'Processing Order'
                            }
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1.5, fontWeight: 'bold', color: '#828282' }} component="div">
                            {order.servicelevel === 'basic' ? 
                                "Company: Not Specified"
                                :
                                JSON.parse(order.analysis?.jobdescriptionjson ?? '{}').jobDescriptionCompanyInfo?.companyName === 'Not specified' ? 'Company: Not Specified' : JSON.parse(order.analysis?.jobdescriptionjson ?? '{}').jobDescriptionCompanyInfo?.companyName ?? 'Processing Order'
                            }
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2">
                            {order.customer.email}<br />
                            Created On: {moment(order.createdAt).format('MM-DD-YYYY hh:mm')}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    {order.processingcomplete ? (
                        <>
                            <Tooltip title="View Your Report">
                                <Button size="small" onClick={handleClick}>
                                    <Visibility />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Download Your Report">
                                <Button size="small" onClick={() => onDownload(order.id)}>
                                    <DownloadIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete Your Report">
                                <Button size="small" onClick={() => onDelete(order.id)}>
                                    <DeleteForeverIcon />
                                </Button>
                            </Tooltip>
                        </>
                        )
                        : 
                        (
                            order.analysis && order.analysis.resumejson ?
                                <ResumeProcessingProgress resumejson={order.analysis.resumejson} />
                                :
                                <Typography variant="body2">
                                    Order Processing
                                </Typography>
                        )
                    }
                </CardActions>
            </Card>
        </Paper>
    )
}

export default ResumeReportCard