import React from 'react'
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const faqs = [
    {
        question: 'What does the license include?',
        answer: 'The license includes unlocks credits which allow you to run your Resume Reports, and grants you full access to all features, free updates, and priority support.'
    },
    {
        question: 'Is my data secure?',
        answer: 'Yes, absolutely. All of your data (resumes you upload, and the Resume Reports generated) are protected by an authorization and security provider. We do have to send the text of the uploaded files to OpenAI to generate the Resume Reports, but because of our license agreement with them, your data is never used for training of their models or made accessible to their team.'
    },
    {
        question: 'How does this work?',
        answer: 'Asking OpenAI to \"make my resume better\" is not sufficient. We are using decades of personnel evaluation as the basis of a complex and orchestrated work flow to generate specific recommended changes to your resume based on the job description you provide. If you want to try ChatGPT to generate similar results, we highly encourage that. Or, you can upload to files, click a button, and get a custom Resume Report with specific recommendations and no hassle.'
    },
    {
        question: 'Do you offer a money back guarantee?',
        answer: 'Sure. If after one credit use you are not 100% satisfied, contact me and let me know that you want a refund. As a product person I would appreciate knowing how we failed to meet your expectations, but I will not ask you to explain. I will simply refund your money and wish you the best of luck in your job search.'
    },
]

function ResumeLicenseFaq() {
    return (
        <Box my={4}>
            <Typography variant="h4" gutterBottom textAlign="center">
                Frequently Asked Questions
            </Typography>
            {faqs.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" fontWeight={"bold"}>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='body1' textAlign={"left"}>
                            {faq.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}

export default ResumeLicenseFaq